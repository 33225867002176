/**
 * Contextual Help vs Matomo
 * We track how many times users opened The Pip in a particular app.
 * Event category - Contextual Help
 * Event Action - current app
 * Event Name - current tag
 *
 * To add new app,add new object in AppConfig.
 */

 import { getNthIdxOf } from 'app/shared/utils';

 interface AppConfig {
   name: string;
   routePattern: string;
 }

 export interface ContextualHelpEvent {
   appName: string;
   tag: string;
 }

 const appsConfig: AppConfig[] = [
   { name: 'Partner Audience Overlaps', routePattern: '/audience-galaxy' },
   { name: 'Apps', routePattern: '/apps' },
   { name: 'Audiences', routePattern: '/audiences' },
   { name: 'Budget', routePattern: '/budget' },
   { name: 'Campaigns', routePattern: '/briefs' },
   { name: 'Cart', routePattern: '/cart' },
   { name: 'Client management', routePattern: '/clients' },
   { name: 'Market admin', routePattern: '/market-admin' },
   { name: 'News', routePattern: '/news' },
   { name: 'Opportunities', routePattern: '/growth-opportunities' },
   { name: 'Retail compass', routePattern: '/retail-compass' },
   { name: 'Scorecards', routePattern: '/scorecards' },
   { name: 'Scorecards management', routePattern: '/sqm' },
   { name: 'Touchpoints', routePattern: '/touchpoints' },
   { name: 'You', routePattern: '/you' },
   { name: 'Provoke the Brief', routePattern: '/provoke-brief' },
   { name: 'Source of Growth', routePattern: '/sog' },
 ];
 const defaultAppName = 'OS';

 export const matchRouteToAppName = (route: string): AppConfig['name'] => {
   let routeWithoutBrandsSuffix = route;
   if (route.startsWith('/brands')) {
     const thirdIdxOfSlash = getNthIdxOf(route, '/', 3);
     routeWithoutBrandsSuffix = route.substring(thirdIdxOfSlash);
   }

   const appName = appsConfig
     .find(config => routeWithoutBrandsSuffix.startsWith(config.routePattern))?.name;
   return appName || defaultAppName;
 };
